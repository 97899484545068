<template>
  <div class="collect-invoice-container">
    <div class="qzf-video-box">
      <qzf-video vid="63dd60f5888d942f725b91fb41271219_6"></qzf-video>
    </div>
    <div class="content_data">
      <div class="content_data_inner_div">
        <div class="w-6b">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each-content" @click="getJzStatus(0)">
            <div class="mr-5">企业总数</div>
            <a>
              <span>{{ sum }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div class="each-content" @click="getJzStatus(1)">
            <div class="mb-5">本期未结账</div>
            <a>
              <span>{{ wjz }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div class="each-content" @click="getJzStatus(4)">
            <div class="mb-5">本期已结账</div>
            <a>
            <span>{{ yjz }}</span
            >家
          </a>
          </div>
        </div>
        <!-- <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div class="each-content" @click="getJzStatus(4)">
            <div class="mb-5">小规模超30万</div>
            <a>
            <span>{{ yjz }}</span
            >家
          </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div class="each-content" @click="getJzStatus(4)">
            <div class="mb-5">小规模超500万</div>
            <a>
            <span>{{ yjz }}</span
            >家
          </a>
          </div>
        </div> -->
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" class="tabs">
      <el-tab-pane label="一般纳税人" name="first"></el-tab-pane>
      <el-tab-pane label="小规模纳税人" name="second"></el-tab-pane>
      <div>
        <div class="top_btns">
          <div>
            <el-button icon="RefreshRight" size="small" class="mr-5" @click="getList()"></el-button>
            <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
            <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" class="w-160 ml-5" clearable size="small" @keyup.enter="getList"/>
            <el-button type="primary" size="small" @click="getList" class="mr-5">
              <el-icon><Search /></el-icon><span> 搜索</span>
            </el-button>
            <search @success="getList" @cancel="cancel">
              <el-form class="styleForm" size="small" label-width="130px" :inline="true">
                <el-form-item label="申报类型：" >
                  <select-common
                    placeholder="请选择"
                    v-model:modelValue="listQuery.accountingType"
                    :options="sblxOptions"
                    class="w140 margin0"
                  />
                </el-form-item>
                <el-form-item label="地区：" >
                  <addressList v-model:type="listQuery.address" class="w140 margin0"></addressList>
                </el-form-item>
                <el-form-item label="选择人员：">
                  <selectuser code="bookkeeping" v-model:userId="listQuery.userId" class="w140 margin0"></selectuser>
                </el-form-item>
                <el-form-item label="报税手机号：">
                  <el-input v-model.trim="listQuery.tel" placeholder="请输入报税人手机号" class="w140 margin0"></el-input>
                </el-form-item>
                <el-form-item label="登录状态：">   
                  <selectAccreditStatus v-model:type="listQuery.keepStatus" class="w140 margin0"></selectAccreditStatus>
                </el-form-item>
                <el-form-item label="结账状态：">
                  <select-common
                    placeholder="请选择"
                    v-model:modelValue="listQuery.jzStatus"
                    :options="jzStatusOptions"
                    class="w140 margin0"
                  />
                </el-form-item>
                <el-form-item label="公司类型："> 
                  <select-common
                    placeholder="请选择公司类型"
                    v-model:modelValue="listQuery.companyType"
                    :options="typeOptions"
                    class="w140 margin0"
                  />
                </el-form-item>
                <el-form-item label="进项采集任务结果：">
                  <selectBussinessCjStatus
                    v-model:allStatus="listQuery.inAllStatus"
                  ></selectBussinessCjStatus>
                </el-form-item>
                <el-form-item
                  label="销项采集任务结果："
                >
                  <selectBussinessCjStatus
                    v-model:allStatus="listQuery.outAllStatus"
                    :type="false"
                  ></selectBussinessCjStatus>
                </el-form-item>
                <el-form-item label="进项采集失败原因："> 
                  <el-input v-model="listQuery.inErrLog" clearable class="w140 margin0"></el-input>
                </el-form-item>
                <el-form-item label="销项采集失败原因："> 
                  <el-input v-model="listQuery.outErrLog" clearable class="w140 margin0"></el-input>
                </el-form-item>
              </el-form>
            </search>
          </div>
          <div>
            <el-button size="small" type="primary" @click="declaration()" :disabled="!$buttonStatus('fpcj_fpcj')">
              <el-icon><Aim /></el-icon><span  >发票采集</span>
            </el-button>
            <!-- <el-dropdown size="small" style="margin-left:10px" :hide-on-click="false" trigger="click">
              <el-button size="small" type="primary">
                撤销任务<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu style="text-align:center">
                  <el-dropdown-item command="a" :disabled="!$buttonStatus('fpcj_cxjxcj')">
                    <span @click="cancelTask('in')">撤销进项采集</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="b" :disabled="!$buttonStatus('fpcj_cxxxcj')">
                    <span @click="cancelTask('out')">撤销销项采集</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown> -->
          </div>
        </div>
        <div class="none-borders">
          <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" v-loading="loading" @sort-change="sortChange" @select-all="handleSelectionChangeAll" ref="tableScrollIn" id="tableLazyLoadIn">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
              <template #default="scope">
                <TableSortCell :row="scope.row" idKey="comId" />
              </template>
            </el-table-column>
            <el-table-column align="left" prop="name" label="公司名称" min-width="300" fixed>
              <template #default="scope">
               <TagNameCopy :row="scope.row"  idKey="comId"></TagNameCopy>
              </template>
            </el-table-column>
            <el-table-column align="center" label="地区" width="80">
              <template #default="scope">
                <span >{{$cityFilter(scope.row?.district)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inAmount1" label="进项发票金额" min-width="140">
              <template #default="scope">
                <div class="div_p">
                  <div style="width: 80%;">
                    <div v-if="scope.row?.type == 2">
                      <div v-if="!scope.row?.inAmount3*1&& scope.row?.inAmount1*1">
                        <div v-if="scope.row?.inAmount1*1">金额：{{parseFloat(scope.row?.inAmount1)}}</div>
                        <div v-else>金额：0.00</div>
                      </div>
                      <div v-else>
                        <div v-if="scope.row?.inAmount1*1">金额：{{parseFloat(scope.row?.inAmount1)}}</div>
                        <div v-else>金额：0.00</div>
                        <div v-if="(scope.row?.inAmount3*100 - scope.row?.inAmount1*100)/100 != 0">税额：{{((scope.row?.inAmount3*100 - scope.row?.inAmount1*100)/100).toFixed2()}}</div>
                        <div v-else>税额：0.00</div>
                      </div>
                    </div>
                    <div v-if="scope.row.type == 1">
                      <div v-if="!scope.row?.inAmount3*1&& scope.row?.inAmount1*1">
                        <div v-if="scope.row?.inAmount1*1">金额：{{parseFloat(scope.row?.inAmount1)}}</div>
                        <div v-else>金额：0.00</div>
                      </div>
                      <div v-else>
                        <div v-if="scope.row?.inAmount3 *1">价税合计：{{Number(scope.row?.inAmount3).toFixed2()}}</div>
                        <div v-else>价税合计：0.00</div> 
                        <div v-if="(scope.row?.inAmount3*100 - scope.row?.inAmount1*100)/100 != 0">税额：{{((scope.row?.inAmount3*100 - scope.row?.inAmount1*100)/100).toFixed2()}}</div>
                        <div v-else>税额：0.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="districtName" label="进项采集任务状态" width="260">
              <template #default="scope">
                <div class="div_p">
                  <div class="item_icon">
                    <p>
                      <el-tooltip :disabled="(scope.row?.inErrLog?scope.row?.inErrLog:'' + scope.row?.inBusinessLog?scope.row.inBusinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                        <template #content>
                          <div v-html="scope.row?.inErrLog?scope.row.inErrLog:'' + scope.row?.inBusinessLog?scope.row.inBusinessLog:''"></div>
                        </template>
                        <div>
                          <div style="display:inline-block" v-if="scope.row?.inImg && (scope.row?.inTaskStatus == 3 && scope.row?.inBusinessStatus != 2)" class="demo-image__preview">
                            <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row?.inBusinessStatus == 5" @click="open(scope.row, scope.$index)"></i>
                            <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else @click="open(scope.row, scope.$index)"></i>
                          </div>
                          <i v-else :class="$batchSendIconCj(scope.row?.inTaskStatus, scope.row?.inBusinessStatus)"></i>
                          <span style="padding-left: 0 ;">{{$batchSendCj(scope.row?.inTaskStatus, scope.row?.inBusinessStatus)}}</span> 
                          <span v-if="scope.row?.inAmount2 != '' && scope.row?.inBusinessStatus == 2" style="margin-left: 5px;">{{ scope.row?.inAmount2 }}张</span>
                          <i class="iconfont icon-wenhao" v-if="(scope.row?.inErrLog && scope.row.inErrLog != '成功')|| (scope.row?.inBusinessLog && scope.row?.inBusinessLog !='成功')" style="font-size:15px;color: red;margin-left: 4px;"></i>
                        </div>
                      </el-tooltip>
                    </p>
                    <span>{{scope.row?.inEndAt}}</span>
                  </div>
                  <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row,'in')" size="small" type="text">采集记录</el-button></p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="outAmount1" label="销项发票金额" min-width="140">
              <template #default="scope">
                <div class="div_p">
                  <div style="width: 80%;">
                    <div v-if="scope.row?.type == 2">
                      <div v-if="!scope.row?.outAmount3*1&& scope.row?.outAmount1*1">
                        <div v-if="scope.row?.outAmount1*1">金额：{{parseFloat(scope.row?.outAmount1)}}</div>
                        <div v-else>金额：0.00</div>
                      </div>
                    <div v-else>
                      <div v-if="scope.row?.outAmount1*1">金额：{{parseFloat(scope.row?.outAmount1)}}</div>
                      <div v-else>金额：0.00</div>
                      <div v-if="(scope.row?.outAmount3*100 - scope.row?.outAmount1*100)/100 != 0">税额：{{((scope.row?.outAmount3*100 - scope.row?.outAmount1*100)/100).toFixed2()}}</div>
                      <div v-else>税额：0.00</div>
                    </div>
                    </div>
                    <div v-if="scope.row.type == 1">
                      <div v-if="!scope.row?.outAmount3*1&& scope.row?.outAmount1*1">
                        <div v-if="scope.row?.outAmount1*1">金额：{{parseFloat(scope.row?.outAmount1)}}</div>
                        <div v-else>金额：0.00</div>
                      </div>
                      <div v-else>
                        <div v-if="scope.row?.outAmount3*1">价税合计：{{Number(scope.row?.outAmount3).toFixed2()}}</div>
                        <div v-else>价税合计：0.00</div>
                        <div v-if="(scope.row?.outAmount3*100 - scope.row?.outAmount1*100)/100 != 0">税额：{{((scope.row?.outAmount3*100 - scope.row?.outAmount1*100)/100).toFixed2()}}</div>
                        <div v-else>税额：0.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="districtName" label="销项采集任务状态" width="260">
              <template #default="scope">
                <div class="div_p">
                  <div class="item_icon">
                    <p>
                      <el-tooltip :disabled="(scope.row?.outErrLog?scope.row.outErrLog:'' + scope.row?.outBusinessLog?scope.row.outBusinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">        
                        <template #content>
                          <div v-html="scope.row?.outErrLog?scope.row.outErrLog:'' + scope.row?.outBusinessLog?scope.row.outBusinessLog:''"></div>
                        </template>
                        <div>
                          <div style="display:inline-block" v-if="scope.row?.outImg && (scope.row?.outTaskStatus == 3 && scope.row?.outBusinessStatus != 2)" class="demo-image__preview">
                            <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row?.outBusinessStatus == 5" @click="openOut(scope.row, scope.$index)"></i>
                            <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else @click="openOut(scope.row, scope.$index)"></i>
                          </div>
                          <i v-else :class="$batchSendIconCj(scope.row?.outTaskStatus, scope.row?.outBusinessStatus)"></i>
                          <span style="padding-left: 0 ;"> {{$batchSendCj(scope.row?.outTaskStatus, scope.row?.outBusinessStatus)}}</span>
                          <span v-if="scope.row?.outAmount2 != ''&& scope.row?.outBusinessStatus == 2" style="margin-left: 5px;">{{ scope.row?.outAmount2 }}张</span>
                          <i class="iconfont icon-wenhao" v-if="(scope.row?.outErrLog &&scope.row.outErrLog != '成功') || (scope.row?.outBusinessLog && scope.row.outBusinessLog !='成功')" style="font-size:15px;color: red;margin-left: 4px;"></i>
                        </div>
                      </el-tooltip>
                      
                    </p>
                    <span>{{scope.row.outEndAt}}</span>
                  </div>
                  <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row,'out')" size="small" type="text">采集记录</el-button></p>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="开票额" min-width="150">
              <template #default="scope">
                <el-tooltip effect="dark" placement="top-start">
                  <template #content>
                    <div>
                      季度开票额：{{ scope.row.outJdAmount }}
                    </div>
                    <div>
                      本年开票额：{{ scope.row.outYearAmount }}
                    </div>
                  </template>
                  <div class="amount_style">
                    <div>
                      季度：{{ scope.row.outJdAmount }}
                    </div>
                    <div>
                      本年：{{ scope.row.outYearAmount }}
                    </div>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <el-table-column prop="jotDown" label="随手记" min-width="150" show-overflow-tooltip>
              <template #default="scope">
                <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
                <span>{{scope.row?.jotDown}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="manager" label="操作" width="100">
              <template #default="scope">
                <div class="div_p1">
                  <el-button @click="handleCheckDetail(scope.row)" size="small" type="text" :disabled="!$buttonStatus('fpcj_cjmx')">明细</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="collectInvoice"/>
        </div>
      </div>
    </el-tabs>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <!-- 采集记录 -->
  <declareDetails ref="declareDetails"></declareDetails>
  <!-- 采集弹窗 -->
  <collectGather ref="collectGather" @success="getList"></collectGather>
  <!-- 税局小助手未安装提示 -->
  <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
  <collectInvoiceDetail ref="collectInvoiceDetail" />
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
</template>

<script>
import { companyInvoiceList } from "@/api/company"
import { currentAccountPeriod } from "@/utils";
import addressList from "@/components/Screening/address";
import selectBussinessCjStatus from "./components/select/selectBussinessCjStatus.vue";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import collectGather from "./components/collectGather.vue";
import collectInvoiceDetail from "./components/collectInvoiceDetail.vue";
import declareDetails from "./components/declareDetails.vue";
import sbImgUrl from "./components/sbImgUrl.vue";
import { quitTask } from "@/api/collection"
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "collectInvoice",
  components: {
    addressList,
    selectBussinessCjStatus,
    selectAccreditStatus,
    declareDetails,
    collectGather,
    sbImgUrl,
    collectInvoiceDetail,
    jotDown,
    TagNameCopy,
    TableSortCell
  },
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName: "first",
      value: "1",
      listQuery: {
        period: currentAccountPeriod(),
        name: "",
        page: 1,
        limit: 20,
        inAllStatus: [],
        outAllStatus: [],
        accountingType: 0,
        address: "",
        jzStatus: 0,
        companyType:'',
        type:'2'
      },
      sblxOptions:[
        {
          value: 0,
          label: "全部",
        },
        {
          value: 2,
          label: "税款申报",
        },
        {
          value: 1,
          label: "零申报",
        },
      ],
      jzStatusOptions: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "未结账",
        },
        {
          value: 4,
          label: "已结账",
        },
      ],
      total:0,
      list:[],
      gsMainIds:[],
      pageStatus: true,
      gsMainType: [],  //企业类型
      gsMainAddress:[], //企业地区
      inReqNos: [],
      outReqNos: [],
      fullscreenLoading:false,
      contentStyleObj:{},
      loading:false,
      wjz:0,
      yjz:0,
      sum:0,
      selects:[],
      totalPage:0,
      pageSize:30,
      tableData:[],
      currentPage:1,
      allChecked:false
    };
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  mounted() {
    this.contentStyleObj= this.$getHeight(310)
    this.listQuery.limit = localStorage.getItem('collectInvoice') ? localStorage.getItem('collectInvoice')*1 : 20
    this.initBus()
    this.getList()
  },
  methods: {
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      let originType = this.listQuery.type
      this.listQuery={
        period: currentAccountPeriod(),
        name: "",
        page: 1,
        limit: originLimit,
        type:originType,
        userId:null,
        keepStatus:null,
        inAllStatus: [],
        outAllStatus: [],
        address: "",
        jzStatus: 0,
        tel:"",
        accountingType: 0,
        companyType:''
      }
      this.$nextTick(() => {
        this.getList()
      });
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([1,2,99].includes(res.outTaskStatus) || [1,2,99].includes(res.inTaskStatus)) {
            status = true;
          }
        })
        if(status == true) {
          companyInvoiceList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total;
            this.sum = res.data.data.sum;
            this.wjz = res.data.data.wjz;
            this.yjz = res.data.data.yjz
            this.setScroll()
          })
        }
      });
    },
    getList(){
        this.loading = true
        companyInvoiceList(this.listQuery).then(res=>{
          this.loading = false
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total;
          this.sum = res.data.data.sum;
          this.wjz = res.data.data.wjz;
          this.yjz = res.data.data.yjz
          this.setScroll()
        })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      this.gsMainAddress =[]
      this.gsMainType = []
      this.inReqNos = []
      this.outReqNos = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoadIn");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScrollIn.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                    that.$refs.tableScrollIn.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getJzStatus(type){
      this.listQuery.jzStatus = type
      this.getList()
    },
    getIds(){
      this.gsMainIds = []
      this.gsMainAddress =[]
      this.gsMainType = []
      this.inReqNos = []
      this.outReqNos = []
      this.selects.map(item=>{
        this.gsMainIds.push(item.comId)
      })
      this.selects.map(item=>{
        this.gsMainType.push(item.type)
      })
      this.selects.map(item=>{
        this.gsMainAddress.push(item.district)
      })
      this.selects.map(item=>{
        this.inReqNos.push(item.inReqNo)
      })
      this.selects.map(item=>{
        this.outReqNos.push(item.outReqNo)
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
        this.getIds()
      }else{
        this.selects = []
        this.gsMainIds = []
        this.gsMainAddress =[]
        this.gsMainType = []
        this.inReqNos = []
        this.outReqNos = []
        this.allChecked = false
      }
    },
    // 采集记录
    declareDetail(e,type) {
      if(type == 'in'){
        this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-inInvoice')
      }else{
        this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-outInvoice')
      }
    },
    //批量采集
    declaration() {
      if(this.gsMainIds.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1)
        return
      }
      //过滤登录状态
      let str = ''
      this.selects.map(v=>{
        if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
          str = v.name + '未授权登录，请先授权再发起任务！'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      //过滤纳税人
      let typeStatus = false
      this.gsMainType.map(v=>{
        if(v != this.gsMainType[0]){
          typeStatus = true
          return
        }
      })
      if(typeStatus){
        this.$qzfMessage("请选择相同纳税人类型公司", 1)
        return
      }
      this.$refs.collectGather.init(this.gsMainIds,this.listQuery.period,this.gsMainType[0],this.gsMainAddress[0])
    },
    // 查看明细
    handleCheckDetail(row){
      this.$refs.collectInvoiceDetail.init(row,this.listQuery.period)
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.inImg ? 'https://file.listensoft.net' + res.inImg + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    openOut(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.outImg ? 'https://file.listensoft.net' + res.outImg + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    cancelTask(type){
      let reqNos = this.inReqNos
      if(type == 'out'){
        reqNos = this.outReqNos
      }
      if(reqNos.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        quitTask({reqNos:reqNos}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }else{
            this.loading = false
          }
        })
      })
    },
    handleClick(tab) {
      this.activeName = tab.props.name;
      if (tab.props.name == "first") {
        this.listQuery.type = '2'
      } else if (tab.props.name == "second") {
        this.listQuery.type = '1'
      }
      this.getList()
    },
  },
};
</script>

<style lang="scss" scoped>
.amount_style{
  width: 100%;
  cursor: pointer;
  & > div{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.content_data {
  .content_data_inner_div {
    display: flex; 
    align-items: center;
    width:98%;
    margin: 0 auto;
    height:70px
  }
}
.collect-invoice-container {
  position: relative;
}
.qzf-video-box {
  position: absolute;top: 10px;right: 13px;z-index:999
}
.styleForm {
  margin:0px 0px 0px 6px;
  width:615px;
}
</style>
<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px 0;
}

.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-borders .div_p {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.item_icon{
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    font-size: 13px;
    color: #333;
    text-align: left;
  }

}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
</style>

<style lang="scss" scoped>
  :deep(.el-tabs--border-card>.el-tabs__content){
    padding: 0px 15px 5px 15px;
  }
  :deep(.el-range-input){
    font-size: 13px !important;
  }
  :deep(.el-range-separator){
    font-size: 13px !important;
  }
  .right_box{
    float: right;
    margin-bottom: 10px;
  }
  .content_data {
    margin: 5px;
    background-color: #d8ecff;
    .each {
      width: 15%;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333;
    }
    .each span {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    .each a {
      text-decoration-line: underline;
      text-decoration-color: #141720;
    }
    .icon_bg {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #aedcfb;
      text-align: center;
      line-height: 35px;
    }
    .iconfont {
      font-size: 18px;
      color: #17a2b8;
    }
}
.w140{
  width: 140px !important;
}
.margin0{
  margin: 0;
}
</style>

<style lang="scss">
  .radioButton .el-radio-button__inner{
    line-height: 14px;
    font-size: 13px;
  }
  .rangeDate .el-range-editor--small .el-range-input{
    font-size: 13px !important;
  }
  .each-content {
    margin-left:5px;cursor: pointer;
  }
</style>
