<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" width="400px" title="银行流水导入">
    <div class="import-bank">
      <div v-for="item in list" :key="item.value" class="item">
        <div>
          <img :src="item.img" alt="">
        </div>
        <el-button size="small" type="primary" icon="UploadFilled" @click="selectValue(item.value)"> {{ item.name
          }}</el-button>

      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
const dialogFormVisible = ref(false)
const emit = defineEmits()
const list = [
  {
    img: require("@/assets/exc.png"),
    name: "银行流水",
    value: 1,
  },
  {
    img: require("@/assets/pdf.png"),
    name: "银行回单",
    value: 2
  },
]
const init = () => {
  dialogFormVisible.value = true
}
const selectValue = (val) => {
  emit('selectValue', val)
  dialogFormVisible.value = false
}
defineExpose({ init })
</script>

<style scoped lang="scss">
.import-bank {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.item {
  background-color: #f5f6fa;
  border: 1px dashed #d9dbdc;
  text-align: center;
  padding: 15px 20px;
  border-radius: 4px;
  img{
    width: 45px;
    height: auto;
    margin-bottom: 10px;
  }
}
</style>
